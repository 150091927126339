@import "_variables.scss";

html body [data-ca3_iconfont="ETmodules"]::before {
    font-family: "ETmodules";
}

[data-ca3_icon]::before {
    font-weight: normal;
    content: attr(data-ca3_icon);
}

.ca3-scroll-down-arrow {
    background-image: url(./svg/hero-down-arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.ca3-scroll-down-link {
    cursor: pointer;
    height: 30px;
    margin-top: 10px;
    line-height: 60px;
    color: #FFF;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    -webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
    -moz-animation: ca3_fade_move_down 2s ease-in-out infinite;
    animation: ca3_fade_move_down 2s ease-in-out infinite;
}
/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
    0% {
        -webkit-transform: translate(0,-20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(0,10px);
        opacity: 0;
    }
}

@-moz-keyframes ca3_fade_move_down {
    0% {
        -moz-transform: translate(0,-20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -moz-transform: translate(0,10px);
        opacity: 0;
    }
}

@keyframes ca3_fade_move_down {
    0% {
        transform: translate(0,-20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0,10px);
        opacity: 0;
    }
}

@media only screen and (min-width:$md) {
    .ca3-scroll-down-link {
        height: 35px;
        bottom: 15%;
    }
}

@media only screen and (min-width:$lg) {
    .ca3-scroll-down-link {
        height: 40px;
        bottom: 18%;
    }
}

@media only screen and (min-width:$xl) {
    .ca3-scroll-down-link {
        height: 50px;
        bottom: 15%;
    }

    @-webkit-keyframes ca3_fade_move_down {
        0% {
            -webkit-transform: translate(0,-20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -webkit-transform: translate(0,20px);
            opacity: 0;
        }
    }

    @-moz-keyframes ca3_fade_move_down {
        0% {
            -moz-transform: translate(0,-20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -moz-transform: translate(0,20px);
            opacity: 0;
        }
    }

    @keyframes ca3_fade_move_down {
        0% {
            transform: translate(0,-20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: translate(0,20px);
            opacity: 0;
        }
    }
}
