html, body {
  font-family: Roboto;
}

#root {
  background-color: black;
  z-index: -2;
  min-height: 100vh;
}
  
.header-logo-wrapper {
  z-index: 1000;
  left: 0;
  top: -11px;
}

.header-logo {
    display: block;
    height: 100%;
    width: 100%;
}

.header-logo svg {
    fill: #fff;
    width: 150px;
    height: auto
}

.bg {
  background-image: url("/assets/bg_sky.png");
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header-style{

    color: #0F5490;
    font-family: Roboto, Regular;
    background-color: #fff;
    font-size: 2 rem;
    font-weight: 200;
    

}

.background-image:after {
  content: ' ';
  z-index: -1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.display-checkbox{
  label{
      div.mat-checkbox-inner-container{
          margin: 0.5rem 1rem  2rem !important;
      }
  }
  label.mat-checkbox-layout{
      align-items:left !important;
  }
}

.RobotoFont{
  font-family: Roboto, Thin;
  background-color: #fff;
  color: #0F5490;
}

.mat-card .mat-divider {
  left: 20px !important; 
  width: 85% !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 
  .mat-stepper-horizontal .mat-horizontal-stepper-header {
    display: inline !important;
    height: 59px;
    overflow: hidden;
    align-items: center;
    padding: 15px !important ; 
  }
  .formWitdth {
    width: auto;
  }
  .RsideStyle {
    justify-content: center;
    width: auto;
    border-radius: 4px;
    margin-left:0;
    margin-right: 0;
    margin-top: 20%;
  }
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 
  .mat-stepper-horizontal .mat-horizontal-stepper-header {
    display: inline !important;
    height: 59px;
    overflow: hidden;
    align-items: center;
    padding: 15px !important ; 
  }
  .formWitdth {
    width: auto;
  }
  .RsideStyle{
    justify-content: center;
    width: auto;
    border-radius: 4px;
    margin-left:0;
    margin-right: 0;
    margin-top: 20%;
  }
}
/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */


/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .mat-stepper-horizontal .mat-horizontal-stepper-header {
    display: inline !important;
    overflow: hidden;
    align-items: center;
    padding: 15px !important ; 
  }
}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
  .mat-stepper-horizontal .mat-horizontal-stepper-header {
    display: inline !important;
    height: 59px;
    overflow: hidden;
    align-items: center;
    padding: 15px !important ; 
  }
}


/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen 
and (device-width: 360px) 
and (device-height: 640px) 
and (-webkit-device-pixel-ratio: 4) {
  .mat-stepper-horizontal .mat-horizontal-stepper-header {
    display: inline !important;
    height: 59px;
    overflow: hidden;
    align-items: center;
    padding: 15px !important ; 
  }
}

/* Portrait */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: portrait) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
    
}

/* Landscape */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: landscape) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
      
     
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
    
}

/* Portrait */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
    
     
}

/* Landscape */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: landscape) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
    
     
}


/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }


}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .mat-stepper-horizontal .mat-horizontal-stepper-header {
        /* display: flex; */
        display: inline !important;
        height: 59px;
        overflow: hidden;
        align-items: center;
        padding: 15px !important ; 
      
      }
   

}