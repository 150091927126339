﻿
//Fonts
@font-face{
    font-family:Mulish;
    src: url(./fonts/Mulish-Regular.ttf) format('truetype');
}
@font-face {
    font-family: Poppins;
    src: url(./fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
    font-family: Poppins-Italic;
    src: url(./fonts/Poppins-Italic.ttf) format('truetype');
}

$primary-font:Mulish;
$secondary-font: Poppins;
$secondary-font-italic: Poppins-Italic;

//CSS Colors
$black-header: #2E3138;
$light-black: #373F41; //font color
$red-violet: #E71483; // active font-color
$lavender-blush: #FEF1F8; // header sub nav active box
$black-background: black;
$black-location-box: #120C13;
$location-opening-hours: #241926;
$gray-dark: #737B7D;
$green: #33CC33;
$white: #ffff;

$link-hover-black: #212529;
$bg-footer: #0D0E0F;


//Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
